import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import PrepaidPackageUpdateForm from "../components/PrepaidPackageUpdateForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { MUTATION_DELETE_PREPAID_PACKAGE } from "../api/mutations";
import { MUTATION_UPDATE_PREPAID_PACKAGE } from "../api/mutations";
import { Navigate } from "react-router";
import { QUERY_GET_PREPAID_PACKAGE } from "../api/queries";
import { getPrepaidPackageDetailUrl } from "../api/urls";
import { useParams } from "react-router-dom";

function loadListCheckIn(checkInPrepaidPackage) {
  let foundingSource;
  let listCheckIn = checkInPrepaidPackage.map((s) => {
    foundingSource = !!s?.prepaidPackage?.packagePlan?.shortName
      ? (foundingSource = s?.prepaidPackage?.packagePlan?.shortName)
      : (foundingSource = s?.checkinFundingSource?.name);

    return {
      uuid: s?.uuid,
      checkInDate: s?.date,
      checkInPlanShortName: foundingSource,
      checkInProductName: s?.product?.name,
      checkInProductUuid: s?.product?.uuid,
    };
  });
  return listCheckIn;
}

function PrepaidPackageUpdatePage() {
  let { prepaidPackageUuid } = useParams();

  const [
    updatePrepaidPackagetMutation,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(MUTATION_UPDATE_PREPAID_PACKAGE);

  const [
    deletePrepaidPackagetMutation,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(MUTATION_DELETE_PREPAID_PACKAGE);

  const [shouldRedirectToList, setShouldRedirectToList] = useState(false);
  const { loading, error, data } = useQuery(QUERY_GET_PREPAID_PACKAGE, {
    variables: {
      prepaidPackageuuid: prepaidPackageUuid,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error | deleteError) {
    return <ErrorMessage errors={[error]} />;
  }

  const currentLocation = data?.userSetting?.currentLocation;
  const isSameLocation =
    currentLocation?.uuid === data?.getPrepaidPackage?.location?.uuid
      ? true
      : false;
  const counterCheckIn = data?.checkInCounter;
  const checkinList = loadListCheckIn(data?.checkInPrepaidPackage);
  let firstCheckin;
  let lastCheckin;
  if (checkinList.length > 0) {
    lastCheckin = checkinList[0].checkInDate;
    firstCheckin = checkinList[checkinList.length - 1].checkInDate;
  }

  let redirectContinueToList = null;
  if (!!shouldRedirectToList || !isSameLocation) {
    redirectContinueToList = (
      <Navigate
        to={{
          pathname: getPrepaidPackageDetailUrl(prepaidPackageUuid),
        }}
      />
    );
  }

  let redirectDeleteCompleted = null;

  if (!!deleteData) {
    redirectDeleteCompleted = (
      <Navigate
        to={{
          pathname: getPrepaidPackageDetailUrl(prepaidPackageUuid),
        }}
      />
    );
  }

  return (
    <>
      <PrepaidPackageUpdateForm
        data={data}
        firstCheckin={firstCheckin}
        lastCheckin={lastCheckin}
        counterCheckIn={counterCheckIn}
        checkInNotPresentCounter={data?.checkInNotPresentCounter}
        deletePrepaidPackage={deletePrepaidPackagetMutation}
        updatePrepaidPackage={updatePrepaidPackagetMutation}
        creating={mutationLoading}
        completed={!!mutationData}
        createError={mutationError}
        deleting={deleteLoading}
        modalContinueClicked={() => {
          setShouldRedirectToList(true);
        }}
      />
      {redirectDeleteCompleted}
      {redirectContinueToList}
    </>
  );
}

export default PrepaidPackageUpdatePage;

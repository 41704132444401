import * as yup from "yup";

import React, { useState } from "react";
import { formatDateIso, isEmpty } from "../api/utils";
import {
  getPrepaidPackageCheckinsListUrl,
  getPrepaidPackageDetailUrl,
  getCustomerUrl,
  getPlanUrl,
} from "../api/urls";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { ErrorMessage } from "./ErrorMessage";
import Form from "react-bootstrap/Form";
import FormElement from "./FormElement";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import addBsArrowRight from "./AddBsArrowRight";
import constants from "../constants";
import dropDownHeaderView from "./DropDownHeaderView";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loadPrepaidPackageTranslationState } from "../api/utils";

const VALIDATION_MANDATORY_FIELD = "Campo obbligatorio";
const schema = yup.object().shape({
  startDate: yup
    .date()
    .max(new Date(), "La data non può essere nel futuro")
    .required(VALIDATION_MANDATORY_FIELD),
  endDate: yup.date().required(VALIDATION_MANDATORY_FIELD),
  sessionQuantity: yup
    .number()
    .required(VALIDATION_MANDATORY_FIELD)
    .positive("Il numero deve essere positivo")
    .typeError("Deve essere un numero"),
});
const DEFAULT_PLAN_UUID = "4f8f890c-91a0-466f-bf59-f44ce61223cc"; // Laser 89

const prepaidPackageState = {
  ACTIVE: constants.PREPAID_PACKAGE_STATE_ACTIVE_KEY,
  CLOSED: constants.PREPAID_PACKAGE_STATE_CLOSED_KEY,
  EXPIRED: constants.PREPAID_PACKAGE_STATE_EXPIRED_KEY,
  CANCELED: constants.PREPAID_PACKAGE_STATE_CANCELED_KEY,
};

function ConfirmDeleteModal({ onConfirm, onCancel, show = false }) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Annulla Pacchetto Prepagato</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Questa azione elimina in modo definitivo i dati del pacchetto prepagato
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Annulla operazione
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Annulla Pacchetto Prepagato
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ContinueModal({ onContinue, show = false }) {
  return (
    <Modal show={show} onHide={onContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Modifica Pacchetto Prepagato</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Il Pacchetto Prepagato è stato aggiornato correttamente
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onContinue}>
          Continua
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function PrepaidPackageUpdateForm({
  data,
  firstCheckin,
  lastCheckin,
  counterCheckIn,
  checkInNotPresentCounter,
  deletePrepaidPackage,
  updatePrepaidPackage,
  creating,
  completed,
  createError,
  deleteCompleted = () => {},
  modalContinueClicked = () => {},
}) {
  const [showModal, setShowModal] = useState(false);
  const [showModalContinue, setShowModalContinue] = useState(false);
  const [msgValue, setMsgValue] = useState(null);

  const prepaidPackageData = data?.getPrepaidPackage;
  const prepaidPackageUuid = prepaidPackageData?.uuid;
  const statePrepaidPackage = prepaidPackageData?.state;

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: prepaidPackageData?.startDate,
      endDate: prepaidPackageData?.endDate,
      plan: !!prepaidPackageData?.packagePlan?.uuid
        ? prepaidPackageData?.packagePlan?.uuid
        : DEFAULT_PLAN_UUID,
      sessionQuantity: prepaidPackageData?.sessionQuantity,
      notes: prepaidPackageData?.notes,
      state: prepaidPackageState[prepaidPackageData?.state],
    },
  });

  const onDelete = async () => {
    const prepaidPackage = {
      state: constants.PREPAID_PACKAGE_STATE_CANCELED_KEY,
    };
    await deletePrepaidPackage({
      variables: {
        prepaidPackage,
        guid: prepaidPackageUuid,
      },
    })
      .then((result) => {
        if (!!result?.data?.updatePrepaidPackage?.error) {
          // There is an error
          setMsgValue(
            <Alert variant="danger">
              {result?.data?.updatePrepaidPackage?.error?.message}
            </Alert>
          );
        } else {
          deleteCompleted(result);
        }
      })
      .catch((error) => {
        let msg = `${error.message} ${error?.networkError?.result?.errors[0]?.message}`;
        setMsgValue(<Alert variant="danger">{msg}</Alert>);
      });
  };

  const onSubmit = async (formData) => {
    const prepaidPackage = {
      startDate: formatDateIso(formData.startDate),
      endDate: formatDateIso(formData.endDate),
      sessionQuantity: Number(formData.sessionQuantity),
      notes: formData.notes,
    };
    const result = await updatePrepaidPackage({
      variables: {
        prepaidPackage,
        guid: prepaidPackageUuid,
      },
    });
    if (!result?.data?.updatePrepaidPackage?.error) {
      // if (!result?.errors) {
      setShowModalContinue(true);
    }
  };

  const sessionQuantity = `Sessioni (${
    counterCheckIn - checkInNotPresentCounter
  }/${prepaidPackageData?.sessionQuantity})`;

  const validStartDate = prepaidPackageData?.startDate;
  const validEndDate = !!lastCheckin
    ? lastCheckin
    : prepaidPackageData?.endDate;

  const buttonSpinner = <Spinner animation="border" variant="light" />;
  const creatingSuccess = (
    <Alert variant="success"> Registrazione effettuata</Alert>
  );

  const disableUpdate = true
    ? [
        constants.PREPAID_PACKAGE_STATE_CANCELED,
        constants.PREPAID_PACKAGE_STATE_CLOSED,
        constants.PREPAID_PACKAGE_STATE_EXPIRED,
      ].includes(statePrepaidPackage)
    : false;

  let btnUpdate = (
    <Form.Group as={Col} controlId="update">
      <Col>
        <Button
          variant={isEmpty(errors) ? "primary" : "danger"}
          type="submit"
          disabled={creating || disableUpdate}
        >
          {creating ? buttonSpinner : "Aggiorna"}
        </Button>
      </Col>
    </Form.Group>
  );

  let btnDelete = (
    <Dropdown.Item
      onClick={() => {
        setShowModal(true);
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: "130%",
          color: "red",
        }}
      >
        <span role="img" aria-label="danger">
          ⚠️
        </span>{" "}
        Annulla
      </div>
    </Dropdown.Item>
  );

  if (prepaidPackageData?.state !== constants.PREPAID_PACKAGE_STATE_ACTIVE) {
    btnDelete = <Col></Col>;
  }

  let labelInvoiceCustomer = "";
  if (
    prepaidPackageData?.customer?.uuid !==
    prepaidPackageData?.invoicingCustomer?.uuid
  ) {
    labelInvoiceCustomer = (
      <>
        <hr />
        <Row>
          <Col>
            <h5> Cliente Titolare Pagamenti</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6> Nome: </h6>
            <span>{prepaidPackageData?.invoicingCustomer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{prepaidPackageData?.invoicingCustomer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{prepaidPackageData?.invoicingCustomer?.email}</span>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Form
        onSubmit={(e) => {
          return handleSubmit(onSubmit)(e);
        }}
      >
        <Row>
          <Col xs={11}>
            <h3 className="text-center"> Modifica Pacchetto Prepagato </h3>
          </Col>
          <Col xs={1}>
            <ButtonGroup>
              {btnUpdate}
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-secondary"
              >
                {btnDelete}
                <Dropdown.Divider />
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getCustomerUrl(prepaidPackageData?.customer?.uuid)}
                >
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
                <Dropdown.Item
                  href={getPrepaidPackageDetailUrl(prepaidPackageUuid)}
                >
                  {addBsArrowRight("Pacchetto Prepagato")}
                </Dropdown.Item>
                <Dropdown.Item
                  href={getPrepaidPackageCheckinsListUrl(prepaidPackageUuid)}
                >
                  {addBsArrowRight("Elenco Check-In")}
                </Dropdown.Item>
                <Dropdown.Item
                  href={getPlanUrl(prepaidPackageData?.plan?.uuid)}
                >
                  {addBsArrowRight("Piano")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="mt-4" />
        <Row>
          <Col>
            <h6>Nome </h6>
            <span>{prepaidPackageData?.customer?.first} </span>
          </Col>
          <Col>
            <h6>Cognome</h6>
            <span>{prepaidPackageData?.customer?.last}</span>
          </Col>
          <Col>
            <h6>Mail</h6>
            <span>{prepaidPackageData?.customer?.email}</span>
          </Col>
        </Row>
        {labelInvoiceCustomer}
        <div className="mt-4" />
        <Row>
          <Col xs={4}>
            <h6>Piano</h6>
            <span>{prepaidPackageData?.packagePlan?.description}</span>
          </Col>
          <Col xs={4}>
            <h6>Costo</h6>
            <span>{prepaidPackageData?.packagePlan?.price}</span>
          </Col>
          <Col xs={4}>
            <h6>Trattamento</h6>
            <span>{prepaidPackageData?.packagePlan?.product?.name}</span>
          </Col>
        </Row>
        <div className="mt-4" />
        <Row>
          <Col xs={4}>
            <h6>Stato</h6>
            <span>
              {loadPrepaidPackageTranslationState[prepaidPackageData?.state]}
            </span>
          </Col>
          <Col xs={4}>
            <h6>Sede Corrente</h6>
            <span>{prepaidPackageData?.location?.name}</span>
          </Col>
        </Row>
        <hr />
        <Row>
          <Form.Group as={Col} xs={3} controlId="startDate">
            <FormElement
              label="Data Inizio"
              type="date"
              name="startDate"
              register={register}
              errors={errors}
              controlOptions={{ min: validStartDate }}
            ></FormElement>
          </Form.Group>

          <Form.Group as={Col} xs={3} controlId="endDate">
            <FormElement
              label="Data Scadenza"
              type="date"
              name="endDate"
              register={register}
              errors={errors}
              controlOptions={{ min: validEndDate }}
            ></FormElement>
          </Form.Group>
          <Form.Group as={Col} xs={2} controlId="sessionQuantity">
            <FormElement
              label={sessionQuantity}
              type="number"
              name="sessionQuantity"
              register={register}
              errors={errors}
              controlOptions={{
                defaultValue: "1",
                max: 150,
                min: counterCheckIn,
              }}
            ></FormElement>
          </Form.Group>
        </Row>
        <div className="mt-4" />
        <Row>
          <Form.Group as={Col} controlId="notes">
            <FormElement
              label="Note"
              name="notes"
              register={register}
              errors={errors}
              controlOptions={{ rows: "2", as: "textarea" }}
            ></FormElement>
          </Form.Group>
        </Row>
        <Row>
          <ErrorMessage errors={[createError]} />
        </Row>
        <Row>{completed ? creatingSuccess : null}</Row>
        <Row>{msgValue !== "" ? msgValue : null}</Row>
      </Form>
      <ContinueModal
        show={showModalContinue}
        onContinue={() => {
          setShowModalContinue(false);
          modalContinueClicked();
        }}
      />
      <ConfirmDeleteModal
        show={showModal}
        onConfirm={onDelete}
        onCancel={() => {
          setShowModal(false);
        }}
      />
    </>
  );
}

export default PrepaidPackageUpdateForm;

import "./App.css";

import { getCustomerCheckinsListUrl, getCustomerUpdateUrl } from "../api/urls";

import CustomerDetailForm from "../components/CustomerDetailForm";
import { ErrorMessage } from "../components/ErrorMessage";
import { Helmet } from "react-helmet";
import { QUERY_CUSTOMER } from "../api/queries";
import { loadListCheckIn } from "../api/utils";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

function loadListSubscriptions(
  customerSubscriptions,
  customerSubscriptionsInvoicing
) {
  let listSubscription = customerSubscriptions.map((s) => {
    return {
      uuid: s.uuid,
      startDate: s?.startDate,
      paymentType: s?.paymentType,
      stateSubscription: s?.state,
      customerFullName: null,
      planShortName: s?.plan?.shortName,
      paidState: s?.paidState,
      invoicingFullName:
        s?.invoicingCustomer?.uuid === s?.customer?.uuid
          ? null
          : s?.invoicingCustomer?.fullName,
      agreementContract: s?.agreementContract?.uuid,
    };
  });

  for (let k in customerSubscriptionsInvoicing) {
    const {
      uuid,
      startDate,
      paymentType,
      state: stateSubscription,
      customer,
      plan,
      paidState,
      agreementContract,
    } = customerSubscriptionsInvoicing[k];
    listSubscription.push({
      uuid,
      startDate,
      paymentType,
      stateSubscription,
      customerFullName: customer?.fullName,
      planShortName: plan?.shortName,
      paidState,
      invoicingFullName: null,
      agreementContract,
    });
  }

  return listSubscription;
}

function loadListCredit(customerCreditList) {
  let listCredit = customerCreditList.map((s) => {
    return {
      uuid: s?.credit?.uuid,
      startDate: s?.credit?.startDate,
      endDate: s?.credit?.endDate,
      creditType: s?.credit?.creditType?.name,
      sessionQuantity: s?.credit?.sessionQuantity,
      planShortName: s?.credit?.plan?.shortName,
      creditState: s?.credit?.state,
      checkinCount: s?.checkinCount,
    };
  });

  return listCredit;
}

function loadListPrepaidPackage(
  customerPrepaidPackageList,
  customerPrepaidPackageInvoicing
) {
  const listFromPackageList = customerPrepaidPackageList.map((s) => ({
    uuid: s?.PrepaidPackage?.uuid,
    startDate: s?.PrepaidPackage?.startDate,
    statePrepaidPackage: s?.PrepaidPackage?.state,
    customerFullName: null,
    planShortName: s?.PrepaidPackage?.packagePlan?.shortName,
    invoicingFullName:
      s?.PrepaidPackage?.invoicingCustomer?.uuid ===
      s?.PrepaidPackage?.customer?.uuid
        ? null
        : s?.PrepaidPackage?.invoicingCustomer?.fullName,
  }));

  const listFromInvoicing = Object.values(customerPrepaidPackageInvoicing).map(
    ({
      uuid,
      startDate,
      state: statePrepaidPackage,
      customer,
      packagePlan,
    }) => ({
      uuid,
      startDate,
      statePrepaidPackage,
      customerFullName: customer?.fullName,
      planShortName: packagePlan?.shortName,
      invoicingFullName: null,
    })
  );

  return [...listFromPackageList, ...listFromInvoicing];
}

function CustomerDetailPage() {
  const navigate = useNavigate();
  const LIMIT_MAX_RECORD = 3;

  let { guid } = useParams();
  const { loading, error, data } = useQuery(QUERY_CUSTOMER, {
    variables: {
      customerUuid: guid,
      checkInMaxLimit: LIMIT_MAX_RECORD,
    },
  });

  if (loading) {
    return <p>Loading..</p>;
  }
  if (error) {
    return <ErrorMessage errors={[error]} />;
  }
  let customerData = data?.customer;
  const counterCheckIn = data?.checkInCounter;
  const userSetting = data?.userSetting;

  const checkinList = loadListCheckIn(data?.checkInCustomer);
  const subscriptionsList = loadListSubscriptions(
    data?.customerSubscriptions,
    data?.customerSubscriptionsInvoicing
  );

  const listPrepaidPackage = loadListPrepaidPackage(
    data?.customerPrepaidPackageList,
    data?.customerPrepaidPackageInvoicing
  );

  const creditList = loadListCredit(data?.customerCreditList);
  // Sort for dateStart descendig
  subscriptionsList.sort(function (a, b) {
    return new Date(b.startDate) - new Date(a.startDate);
  });

  function openCustomerUpdate(values) {
    navigate(getCustomerUpdateUrl(guid));
  }

  function openCustomerCheckinsList(values) {
    navigate(getCustomerCheckinsListUrl(guid));
  }

  return (
    <>
      <Helmet>
        <title>{`${customerData?.last} ${customerData?.first} | MedexPay`}</title>
        <meta
          name="description"
          content={`${customerData?.last} ${customerData?.first} - ${customerData?.email}`}
        />
      </Helmet>
      <CustomerDetailForm
        data={customerData}
        checkinList={checkinList}
        counterCheckIn={counterCheckIn}
        subscriptionsList={subscriptionsList}
        creditList={creditList}
        openCustomerUpdate={openCustomerUpdate}
        openCustomerCheckinsList={openCustomerCheckinsList}
        userSetting={userSetting}
        agreementPrivacy={data?.getAgreementPrivacy}
        agreementLaser={data?.getAgreementLaser}
        agreementLaserMinor={data?.getAgreementLaserMinor}
        listPrepaidPackage={listPrepaidPackage}
      />
    </>
  );
}

export default CustomerDetailPage;

import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import {
  useTable,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
} from "react-table";
import constants from "../constants";
import dropDownHeaderView from "./DropDownHeaderView";
import addBsArrowRight from "./AddBsArrowRight";
import {
  getPrepaidPackageDetailUrl,
  getCustomerUrl,
  getPlanUrl,
  getCustomerNewCheckInUrl,
} from "../api/urls";

function PrepaidPackageCheckInsList({
  columns,
  data,
  prepaidPackage,
  counterCheckIn,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      useFlexLayout,
      useResizeColumns
    );

  const customer = prepaidPackage?.customer;

  const enabled =
    prepaidPackage?.state === constants.PREPAID_PACKAGE_STATE_ACTIVE;
  const newCheckIn =
    !customer?.needsUpdate && enabled && customer?.uuid ? (
      <Dropdown.Item href={getCustomerNewCheckInUrl(customer?.uuid)}>
        Nuovo Check-In
      </Dropdown.Item>
    ) : null;

  return (
    <>
      <Row>
        <Col xs={8}>
          <h3 className="text-center"> Elenco Check-In Pacchetto Prepagato </h3>
        </Col>
        <Col xs={4}>
          <div
            style={{
              textAlign: "right",
            }}
          >
            <ButtonGroup>
              <DropdownButton
                menualign="right"
                title="Azioni"
                id="dropdown-menu-align-right"
                variant="outline-secondary"
              >
                {newCheckIn}
                <Dropdown.Divider />
                <Dropdown.Header>{dropDownHeaderView()}</Dropdown.Header>
                <Dropdown.Item
                  href={getPrepaidPackageDetailUrl(prepaidPackage?.uuid)}
                >
                  {addBsArrowRight("Pacchetto Prepagato")}
                </Dropdown.Item>
                <Dropdown.Item href={getCustomerUrl(customer?.uuid)}>
                  {addBsArrowRight("Cliente")}
                </Dropdown.Item>
                <Dropdown.Item href={getPlanUrl(prepaidPackage?.plan?.uuid)}>
                  {addBsArrowRight("Piano")}
                </Dropdown.Item>
              </DropdownButton>
            </ButtonGroup>
          </div>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={3}>
          <h6> Data Inizio </h6>
          <span>{prepaidPackage?.startDate} </span>
        </Col>
        <Col xs={3}>
          <h6>Data Scadenza </h6>
          <span>{prepaidPackage?.endDate} </span>
        </Col>
        <Col xs={3}>
          <h6>Piano</h6>
          <span>{prepaidPackage?.packagePlan?.description}</span>
        </Col>
        <Col xs={3}>
          <h6>Sede Corrente</h6>
          <span>{prepaidPackage?.location?.name}</span>
        </Col>
      </Row>
      <div className="mt-4" />
      <Row>
        <Col xs={3}>
          <h6> Sessioni</h6>
          <span>
            {counterCheckIn}/{prepaidPackage?.sessionQuantity}{" "}
          </span>
        </Col>
        <Col xs={3}>
          <h6> Stato </h6>
          <span>{prepaidPackage?.state} </span>
        </Col>
        <Col xs={3}></Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h6>Nome: </h6>
          <span>{customer.first} </span>
        </Col>
        <Col>
          <h6>Cognome</h6>
          <span>{customer.last}</span>
        </Col>
        <Col>
          <h6>Mail</h6>
          <span>{customer.email}</span>
        </Col>
      </Row>

      <div className="mt-4" />

      <Table striped bordered hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? constants.DESCENDING_SORT_SIMBOL
                        : constants.ASCENDING_SORT_SIMBOL
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default PrepaidPackageCheckInsList;
